.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero-text {
  font-size: 1rem; /* Base font size */
}

.hero-text .number-and-img {
  display: inline-block;
  font-size: 1.5em; /* Adjust as needed */
  vertical-align: baseline; /* Align with the baseline of the text */
  line-height: 1; /* Adjust to align with text */
}

.hero-text .number-and-img img {
  display: inline-block;
  height: 0.7em; /* Match the text size */
  width: auto; /* Maintain aspect ratio */
  vertical-align: baseline; /* Align with the baseline of the text */
}




/* Privacy Policy CSS */

.privacy-policy {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.privacy-policy h1, .privacy-policy h2 {
  color: #FB925A;
}

.privacy-policy p, .privacy-policy li {
  line-height: 1.6;
}

.privacy-policy ul {
  padding-left: 20px;
}

.privacy-policy address {
  font-style: normal;
}

.privacy-policy a {
  color: #0056b3;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

