@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

body {
  margin: 0;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-primary{background: #FB925A !important;}
.text-primary{ color: #FB925A !important;}
.btn-primary{ background: #FB925A !important; border-color: #FB925A;}
.text-secondary{color: #00004D !important;}

h1,h2,h3,h4,h5,h6{
  font-family: Inter;
}

h1{
  font-size: 66px;
  font-weight: 800;
  color: #00004D;
  text-transform: uppercase;
}

.h1{
  font-weight: 900;
  font-size: 50px;
}

p{
  font-weight: 400;
  font-size: 30px;
  color: #475467;
  line-height: 55px;
}

.logo img {
  width: 300px;
  height: 34px;
}

.hero-text{
  padding-right:100px ;
}

.head .btn{
  font-weight: 600;
}

.social h3{
  font-weight: 700;
  font-size: 30px;
}

.social .icon{
  background: #FB925A;
  width: 35px;
  height: 35px;
  padding: 10px;
}

.top-img img{
  width: 211px;
  height: 27px;
}

.form h2{
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
}

.form p {
  font-weight: 400;
  font-size: 20px;
  color: #00000080;
}

.form input{
  /* width: 480px; */
  height: 48px;
}

.form input::placeholder{
  color:#667085 ;
  font-size: 16px;
}

.form span{
  color: #475467;
  font-size: 14px;
  margin: 0px;
}

.form button{
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
}

.network h2{
  font-size: 52px;
  line-height: 78px;
  font-weight: 700;
}

.network p{
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #475467;
}

.network-boxes p{
  font-weight: 400;
  font-size: 20px;
  color: #1D1D1B;
}

.network-boxes h3{
  font-size: 32px;
  font-weight: 500;
  color: #1D1D1B;
}

.network-boxes img {
  width: 60px;
  height: 60px;
  background-color: #FA8243;
  padding: 15px;
  border-radius: 40px;
  margin-bottom: 20px;
}

.free h2{
  font-size: 48px;
  font-weight: 600;
}

.free h5{
  font-size: 25px;
  font-weight: 500;
}

.marketing h2{
  font-size: 36px;
  font-weight: 600;
}

.marketing p{
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.sign-up h2{
  font-size: 50px;
  font-weight: 600;
}

.sign-up span{
  font-weight: 900;
}

.sign-up p{
  color: #00000080;
  font-size: 20px;
  font-weight: 400;
}

.footer {
  background-color: #0A142F !important;
  color: #fff ;
}

/* .footer img {
  width: 355px;
  height: 43px;
} */

.footer h6{
  font-size: 19px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 20px;
}

.footer h5{
font-size: 22px;
font-weight: 500;
margin-bottom: 30px;
}

.footer ul li {
  list-style: none;
  font-weight: 300;
  font-family: Inter;
  font-size: 17px;
  color: #F4F4F4;
  margin-bottom: 15px;
}

.footer p{
  font-size: 17px;
  color: #fff;
  margin-top: 0px;
}

@media screen and (max-width: 700px) {
  h1{
    font-size: 49px;
  }

  .h1{
    font-size:30px;
  }

  .network h2{
    font-size: 35px;
  }

  .hero-text{
    padding-right:0px ;
  }
  
  .logo img {
    width: 240px;
    height: 28px;
  }
}